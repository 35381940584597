/*******************************************************
**
**	Menu hover function for > 767px (non mobile screen)
**
********************************************************/

jQuery(function($) {
	$('.navbar .dropdown').hover(function() {
		if($(window).width() > 767) {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(150).slideDown();
		}

	}, function() {
		if($(window).width() > 767) {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
		}
	});

	$('.navbar .dropdown-submenu').hover(function() {
		if($(window).width() > 767) {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(150).slideDown();
		}

	}, function() {
		if($(window).width() > 767) {
			$(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
		}

	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

});

/*******************************************************
**
**	Menu hover function for  mobile screen
**
********************************************************/

jQuery(document).ready(function($){

	$('span.level-trigger').click(function(event) {
		if ( $(this).hasClass('open') ) {
        	$(this).siblings('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
			$(this).removeClass('open');
		} else {
			$(this).addClass('open');
        	$(this).siblings('.dropdown-menu').first().stop(true, true).delay(150).slideDown();
		}
		
    });

});