jQuery(document).ready(function($){
	var nav_height = $('#justicebc-navbar').height();
	$('#justicebc-nav-button').click(function(event) {
		var header_height = $('#justicebc-header').height();
		var caption_height = $('#justicebc-caption').height();
		var new_height;
		if ( $(this).hasClass('collapsed') ) {
			new_height = header_height + nav_height - caption_height;
			$('#justicebc-header').animate( { height:new_height+'px'}, 350);
		} else {
			new_height = header_height - nav_height + caption_height;
			$('#justicebc-header').animate( { height:new_height+'px'}, 350);
		}
    });
});